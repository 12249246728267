import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Footer = ({includeCTA = true}) => {
	return (
		<footer>
			<div className="footer-container">
				{includeCTA && (
					<div className="family-advisor">
						<div className="left-block">
							<h1>
								Talk to a Family <br />
								Care Advisor
							</h1>
							<p className="copy">
								Whether you’re new to Edyn or a long-time client, you’ll get
								compassionate and invaluate support from our expert team.
							</p>
							<ul>
								<li>
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/live-chat.png"
										objectFit="contain"
									/>
									<p>
										<Link to="#" className="underline links">
											Live chat
										</Link>
										<span> Speak with a care expert within 60 seconds</span>
									</p>
								</li>
								<li>
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/phone.png"
										objectFit="contain"

									/>
									<p>
										<Link to="tel:020-3970-9900" className="links">
											020 3970 9900
										</Link>
										<span> We’re open until 7pm, 7 days a week</span>
									</p>
								</li>
							</ul>
							<div className="cta">
								<Link to="">
									<button className="btn-orange">Or arrange a call back</button>
								</Link>
							</div>
						</div>
						<div className="right-block">
							<StaticImage placeholder="blurred"
								className="tablet"
								src="../../static/assets/marketing-site/images/family-advisor.png"
								objectFit='contain'
							/>
							<StaticImage placeholder="blurred"
								className="mobile"
								src="../../static/assets/marketing-site/images/family-advisor-small.png"
								objectFit='contain'
							/>
						</div>
					</div>
				)}
				<div className="footer-background">
					<div className="footer-block mobile">
						<div className="footer-logo">
							<Link to="/index">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/logo-white.png" />
							</Link>
						</div>
						<div className="footer-navigation">
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<Link to="#">Home</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Live-Care</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Service</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Approach</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Pricing</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our carers</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Customer reviews</Link>
									</li>
									{/*   About accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"
												/>
											<span>About</span>
										</Link>
										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li>
													<Link to="#">Why we started</Link>
												</li>
												<li>
													<Link to="#">Our values</Link>
												</li>
												<li>
													<Link to="#">Our team</Link>
												</li>
												<li>
													<Link to="#">Our advisors</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Conditions We Care for accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"
											/>
											<span>Conditions we care for</span>
										</Link>

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="/dementia">Dementia</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Parkinsons</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Palliative care</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Daily active tasks</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Couples care</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Locations accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Locations</span>
										</Link>

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">London</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">East Midlands</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">West Of England</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">North East</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">North West</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">South East</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">South West</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">West Midlands</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Training & Support Accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"
											/>
											<span>Training & Support</span>
										</Link>

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Our training process</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Ongoing support</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Refer-a-friend scheme</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Apply now</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Client Stories Accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Client stories</span>
										</Link>
										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Matthew's story</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Iwona &amp; Neil's story</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Planning & Advice Accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Planing & Advice</span>
										</Link>
										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div className="footer-contact">
							<div className="contact-details">
								<Link to="mailto:hello@edyn.care">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/footer-email.svg"
										objectFit="contain"
									/>
									<p>hello@edyn.care</p>
								</Link>
								<Link to="tel:020-3970-9900">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/footer-phone.svg"
										objectFit="contain"

									/>
									<p>020-3970-9900</p>
								</Link>
							</div>
							<div className="social-links">
								<Link to="https://twitter.com/EdynCare">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/twitter.png"
										objectFit="contain"
									/>
									<p>Twitter</p>
								</Link>
								<Link to="https://www.facebook.com/EdynCare/">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/facebook.png"
										objectFit="contain"
									/>
									<p>Facebook</p>
								</Link>
								<Link to="https://www.instagram.com/edyn.care/">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/instagram.png"
										objectFit="contain"
									/>
									<p>Instagram</p>
								</Link>
							</div>
							<div className="footer-buttons">
								<Link to="" className="button-link">
									<button className="btn">Login</button>
								</Link>
								<Link to="" className="button-link">
									<button className="btn-primary">Arrange a Call</button>
								</Link>
							</div>
						</div>
						<div className="footer-navigation">
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<Link to="">Terms and conditions</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="">Privacy</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="">Refer a friend</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					{/*   -----------------------------------------------------------------------  */}
					{/*                                FOOTER TABLET                               */}
					{/*   -----------------------------------------------------------------------  */}

					<div className="footer-block tablet">
						<div className="footer-contact">
							<div className="footer-contact-container">
								<div className="logo">
									<Link to="/index">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/logo-white.png" />
									</Link>
								</div>
								<div className="footer-buttons">
									<Link to="" className="button-link">
										<button className="btn">Login</button>
									</Link>
									<Link to="" className="button-link">
										<button className="btn-primary">Arrange a Call</button>
									</Link>
								</div>
							</div>
							<div className="footer-contact-container">
								<div className="contact-details">
									<ul>
										<li>
											<Link to="mailto:hello@edyn.care">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/footer-email.svg"
													objectFit="contain"
												/>
												<p>hello@edyn.care</p>
											</Link>
										</li>
										<li>
											<Link to="tel:020-3970-9900">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/footer-phone.svg"
													objectFit="contain"
												/>
												<p>020-3970-9900</p>
											</Link>
										</li>
									</ul>
								</div>
								<div className="social-links">
									<Link to="https://twitter.com/EdynCare">
										<StaticImage placeholder="blurred"
											className="icon-default"
											src="../../static/assets/marketing-site/images/twitter.png"
											objectFit="contain"
										/>
									</Link>
									<Link to="https://www.facebook.com/EdynCare/">
										<StaticImage placeholder="blurred"
											className="icon-default"
											src="../../static/assets/marketing-site/images/facebook.png"
											objectFit="contain"
										/>
									</Link>
									<Link to="https://www.instagram.com/edyn.care/">
										<StaticImage placeholder="blurred"
											className="icon-default"
											src="../../static/assets/marketing-site/images/instagram.png"
											objectFit="contain"
										/>
									</Link>
								</div>
							</div>
						</div>
						<hr className="cargo" />
						<div className="footer-navigation">
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<Link to="#">Home</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Live-Care</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Service</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Approach</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Pricing</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our carers</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Customer reviews</Link>
									</li>
									{/*   About accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>About</span>
										</Link>
										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li>
													<Link to="#">Why we started</Link>
												</li>
												<li>
													<Link to="#">Our values</Link>
												</li>
												<li>
													<Link to="#">Our team</Link>
												</li>
												<li>
													<Link to="#">Our advisors</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Conditions We Care for accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Conditions we care for</span>
										</Link>

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="/dementia">Dementia</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Parkinsons</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Palliative care</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Daily active tasks</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Couples care</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Locations accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Locations</span>
										</Link>

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">London</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">East Midlands</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">West Of England</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">North East</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">North West</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">South East</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">South West</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">West Midlands</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Training & Support Accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Training & Support</span>
										</Link>

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Our training process</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Ongoing support</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Refer-a-friend scheme</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Apply now</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Client Stories Accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Client stories</span>
										</Link>
										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Matthew's story</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Iwona &amp; Neil's story</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Planning & Advice Accordion  */}
									<li className="footer-nav-title link accordion">
										<Link to="#" className="accordion-link">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
												alt=""
												objectFit="contain"

											/>
											<span>Planing & Advice</span>
										</Link>
										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Blog title goes here</Link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
							<div className="footer-nav-about">
								<ul>
									<li className="footer-nav-title link">
										<Link to="">Terms and conditions</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="">Privacy</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					{/*   -----------------------------------------------------------------------  */}
					{/*                                 FOOTER IPAD                                */}
					{/*   -----------------------------------------------------------------------  */}

					<div className="footer-block ipadPro">
						<div className="footer-contact">
							<div className="logo">
								<Link to="/index">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/logo-white.png" />
								</Link>
							</div>
							<div className="contact-details">
								<ul>
									<li>
										<Link to="mailto:hello@edyn.care">
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/footer-email.svg"
												objectFit="contain"

											/>
											<p>hello@edyn.care</p>
										</Link>
									</li>
									<li>
										<Link to="tel:020-3970-9900">
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/footer-phone.svg"
												objectFit="contain"

											/>
											<p>020-3970-9900</p>
										</Link>
									</li>
								</ul>
							</div>
							<div className="social-links">
								<Link to="https://twitter.com/EdynCare">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/twitter.png"
										objectFit="contain"
									/>
								</Link>
								<Link to="https://www.facebook.com/EdynCare/">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/facebook.png"
										objectFit="contain"
									/>
								</Link>
								<Link to="https://www.instagram.com/edyn.care/">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/instagram.png"
										objectFit="contain"
									/>
								</Link>
							</div>
							<div className="footer-buttons">
								<Link to="" className="button-link">
									<button className="btn">Login</button>
								</Link>
								<Link to="" className="button-link">
									<button className="btn-primary">Arrange a Call</button>
								</Link>
							</div>
						</div>

						<hr className="cargo" />
						<div className="footer-navigation">
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<Link to="#">Home</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Live-Care</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Service</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Approach</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our Pricing</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Our carers</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="#">Customer reviews</Link>
									</li>
								</ul>
							</div>
							<div className="footer-nav-about">
								<ul>
									<li className="footer-nav-title">About</li>
									<li className="footer-sub">
										<Link to="#">Why we started</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Our values</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Our team</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Our advisors</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Recruitment process</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Recruitment events</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Day in the life of an Edyn carer</Link>
									</li>
								</ul>
							</div>
							<div className="footer-nav-conditions">
								<ul>
									<li className="footer-nav-title">Conditions we care for</li>
									<li className="footer-sub">
										<Link to="/dementia">Dementia</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Parkinsons</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Palliative care</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Daily active tasks</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Couples care</Link>
									</li>
								</ul>
							</div>
							<div className="footer-nav-locations">
								<ul>
									<li className="footer-nav-title">Locations</li>
									<li className="footer-sub">
										<Link to="#">London</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">East Midlands</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">West Of England</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">North East</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">North West</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">South East</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">South West</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">West Midlands</Link>
									</li>
								</ul>
							</div>
							<div className="footer-nav-column">
								<div className="footer-nav-training">
									<ul>
										<li className="footer-nav-title">Training & Support</li>
										<li className="footer-sub">
											<Link to="#">Our training process</Link>
										</li>
										<li className="footer-sub">
											<Link to="#">Ongoing support</Link>
										</li>
										<li className="footer-sub">
											<Link to="#">Refer-a-friend scheme</Link>
										</li>
										<li className="footer-sub">
											<Link to="#">Apply now</Link>
										</li>
									</ul>
								</div>
								<div className="footer-nav-training">
									<ul>
										<li className="footer-nav-title">Client stories</li>
										<li className="footer-sub">
											<Link to="#">Matthew's story</Link>
										</li>
										<li className="footer-sub">
											<Link to="#">Iwona &amp; Neil's story</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<hr className="cargo" />
						<div className="footer-planning">
							<h3>Planning &amp; advice</h3>
							<div className="planning-navigation">
								<ul>
									<li className="footer-nav-title">
										Starting the conversation
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
								</ul>
								<ul>
									<li className="footer-nav-title">Preparing for care</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
								</ul>
								<ul>
									<li className="footer-nav-title">Paying for care</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Blog title goes here</Link>
									</li>
								</ul>
								<ul>
									<li className="footer-nav-title">Helpful guides</li>
									<li className="footer-sub">
										<Link to="#">Guide title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Guide title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Guide title goes here</Link>
									</li>
									<li className="footer-sub">
										<Link to="#">Guide title goes here</Link>
									</li>
								</ul>
								<ul>
									<li className="footer-nav-title link">
										<Link to="">Terms and conditions</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="">Privacy</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer