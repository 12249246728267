import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import EdynCareLogo from '../components/icons/EdynCare'

export default function Header() {
	return (
		<>
			<nav id="desktop-nav">
				<div className="nav-container">
					<div className="menu-left">
						<div className="hamburger-menu" onclick="navMenuToggle()">
							<div className="bar1"></div>
							<div className="bar2"></div>
							<div className="bar3 short"></div>
						</div>
						<div className="header-logo">
							<Link to="/">
								<EdynCareLogo />
							</Link>
						</div>
						<ul className="menu-list desktop">
							<li>
								<Link to="/liveincare">Live-in Care</Link>
							</li>
							<li>
								<Link to="#">Our Service</Link>
							</li>
							<li>
								<Link className="nav-item dropdown-arrow" to="#">
									How Edyn Works
								</Link>
								{/*   SUB MENU  */}
								<div className="sub-menu">
									<div
										className="sub-menu-container"
										style={{ position: "absolute", left: "380px" }}
									>
										<ul>
											<li>
												<Link to="/approach">
													<span>{">"}</span> Our approach
												</Link>
											</li>
											<li>
												<Link to="/pricing">
													<span>{">"}</span> Our pricing
												</Link>
											</li>
											<li>
												<Link to="">
													<span>{">"}</span> Our carers
												</Link>
											</li>
											<li>
												<Link
													to="https://uk.trustpilot.com/review/edyn.care"
													target="_blank"
												>
													<span>{">"}</span> Customer reviews
												</Link>
											</li>
										</ul>
										{/*   SND SUB MENU  */}
										<ul>
											<li>
												<Link to="#" className="snd-sub-menu-title">
													<span>{">"}</span> About us
												</Link>
												<div className="snd-sub-menu">
													<ul>
														<li>
															<Link to="#">Why we started</Link>
														</li>
														<li>
															<Link to="#">Our values</Link>
														</li>
														<li>
															<Link to="#">Our team</Link>
														</li>
														<li>
															<Link to="#">Our advisors</Link>
														</li>
													</ul>
												</div>
											</li>
										</ul>
										{/*   /SND SUB MENU  */}
										{/*   SND SUB MENU  */}
										<ul>
											<li>
												<Link to="#" className="snd-sub-menu-title">
													<span>{">"}</span> Client stories us
												</Link>
												<div className="snd-sub-menu">
													<ul>
														<li>
															<Link to="#">Matthew's story</Link>
														</li>
														<li>
															<Link to="#">Iwona &amp; Neil's story</Link>
														</li>
													</ul>
												</div>
											</li>
										</ul>
										{/*   /SND SUB MENU  */}
									</div>
								</div>
								{/*   SUB MENU  */}
							</li>
							<li>
								<Link className="dropdown-arrow" to="#">
									Conditions we care for
								</Link>

								{/*   SUB MENU  */}
								<div className="sub-menu">
									<div
										className="sub-menu-container"
										style={{ position: "absolute", left: "550px" }}
									>
										<ul>
											<li>
												<Link to="/dementia">
													<span>{">"}</span> Dementia
												</Link>
											</li>
											<li>
												<Link to="#">
													<span>{">"}</span> Parkinsons
												</Link>
											</li>
											<li>
												<Link to="#">
													<span>{">"}</span> Palliative Care
												</Link>
											</li>
										</ul>
										<ul>
											<li>
												<Link to="#">
													<span>{">"}</span> Daily active tasks
												</Link>
											</li>
											<li>
												<Link to="#">
													<span>{">"}</span> Couples Care
												</Link>
											</li>
										</ul>
									</div>
								</div>
								{/*   SUB MENU  */}
							</li>
							<li>
								<Link className="dropdown-arrow" to="/locations">
									Locations
								</Link>
								{/*   SUB MENU  */}
								<div className="sub-menu">
									<div
										className="sub-menu-container"
										style={{ position: "absolute", left: "750px" }}
									>
										<ul>
											<li>
												<Link to="/locations">
													<span>{">"}</span> London
												</Link>
											</li>
											<li>
												<Link to="/locations">
													<span>{">"}</span> East Midlands
												</Link>
											</li>
											<li>
												<Link to="/locations">
													<span>{">"}</span> West Of England
												</Link>
											</li>
											<li>
												<Link to="/locations">
													<span>{">"}</span> North East
												</Link>
											</li>
										</ul>
										<ul>
											<li>
												<Link to="/locations">
													<span>{">"}</span> North West
												</Link>
											</li>
											<li>
												<Link to="/locations">
													<span>{">"}</span> South East
												</Link>
											</li>
											<li>
												<Link to="/locations">
													<span>{">"}</span> South West
												</Link>
											</li>
											<li>
												<Link to="/locations">
													<span>{">"}</span> West Midlands
												</Link>
											</li>
										</ul>
									</div>
								</div>
								{/*   SUB MENU  */}
							</li>
							<li>
								<Link className="dropdown-arrow" to="#">
									Planning & advice
								</Link>
								{/*   SUB MENU  */}
								<div className="sub-menu">
									<div
										className="sub-menu-container"
										style={{ margin: "0 auto" }}
									>
										{/*   SND SUB MENU  */}
										<ul>
											<li>
												<Link to="#" className="snd-sub-menu-title">
													<span>{">"}</span> Starting the conversation
												</Link>
												<div className="snd-sub-menu">
													<ul>
														<li>
															<Link to="/blog">Blog title goes here</Link>
														</li>
														<li>
															<Link to="blog-post.html">
																Blog title goes here
															</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
													</ul>
												</div>
											</li>
										</ul>
										{/*   /SND SUB MENU  */}
										{/*   SND SUB MENU  */}
										<ul>
											<li>
												<Link to="#" className="snd-sub-menu-title">
													<span>{">"}</span> Preparing for care
												</Link>
												<div className="snd-sub-menu">
													<ul>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
													</ul>
												</div>
											</li>
										</ul>
										{/*   /SND SUB MENU  */}
										{/*   SND SUB MENU  */}
										<ul>
											<li>
												<Link to="#" className="snd-sub-menu-title">
													<span>{">"}</span> Paying for care
												</Link>
												<div className="snd-sub-menu">
													<ul>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
													</ul>
												</div>
											</li>
										</ul>
										{/*   /SND SUB MENU  */}
										{/*   SND SUB MENU  */}
										<ul>
											<li>
												<Link to="#" className="snd-sub-menu-title">
													<span>{">"}</span> Helpful guides
												</Link>
												<div className="snd-sub-menu">
													<ul>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
														<li>
															<Link to="#">Blog title goes here</Link>
														</li>
													</ul>
												</div>
											</li>
										</ul>
										{/*   /SND SUB MENU  */}
									</div>
								</div>
								{/*   SUB MENU  */}
							</li>
						</ul>
					</div>
					<div className="menu-centre">
						<div className="header-logo">
							<Link to="/index">
								<EdynCareLogo />
							</Link>
						</div>
					</div>
					<div className="menu-right">
						<div className="navigation-actions-container">
							<div className="menu-call">
								<StaticImage
									loading="eager"
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/icons/call-icon.png"
									alt="Call Icon"
								/>
								<Link to="#">020 3970 9900</Link>
							</div>
							<Link to="" className="button-link">
								<button className="btn-blue">I'm a carer</button>
							</Link>
							<Link to="" className="button-link">
								<button className="btn-primary">Login</button>
							</Link>
						</div>
						<div className="navigation-actions-mobile-container">
							<Link to="" className="button-link">
								<button className="profile-btn">
									<StaticImage
										loading="eager"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/icons/user-icon.svg"
										alt="User Icon"
									/>
								</button>
							</Link>
						</div>
					</div>
				</div>
			</nav>
			<nav id="mobile-nav">
				<header>
					<div className="header-left">
						<div
							id="hamburger-menu-btn"
							className="hamburger-menu"
							onclick="navMenuToggle()"
						>
							<div className="bar1"></div>
							<div className="bar2"></div>
							<div className="bar3 short"></div>
						</div>
					</div>
					<div className="header-main">
						<div className="header-logo">
							<EdynCareLogo />
						</div>
					</div>
					<div className="header-right">
						<div className="navigation-actions-mobile-container header-right">
							<Link to="" className="button-link">
								<button className="profile-btn">
									<StaticImage
										loading="eager"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/icons/user-icon.svg"
										alt="User Icon"
									/>
								</button>
							</Link>
						</div>
					</div>
				</header>

				<main>
					<div className="navigation-list">
						<ul className="menu-list mobile">
							<li>
								<Link to="/index">Home</Link>
							</li>
							<li>
								<Link to="/liveincare">Live-in Care</Link>
							</li>
							<li>
								<Link to="#">Our Service</Link>
							</li>
						</ul>
					</div>
					<div className="navigation-list">
						<ul className="menu-list mobile">
							<li className="accordion">
								<Link to="#" className="accordion-link">
									<StaticImage
										loading="eager"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
										alt=""
									/>
									<span>How Edyn Works</span>
								</Link>
								<div
									className="accordion-items-container"
									data-collapsed="true"
								>
									<ul>
										<li>
											<Link to="/approach"> Our approach</Link>
										</li>

										<li>
											<Link to="/pricing">Our pricing</Link>
										</li>
										<li>
											<Link to=""> Our carers</Link>
										</li>
										<li>
											<Link
												to="https://uk.trustpilot.com/review/edyn.care"
												target="_blank"
											>
												Customer reviews
											</Link>
										</li>
									</ul>
									<ul>
										<li className="accordion">
											<Link to="" className="snd-sub-menu-title accordion-link">
												<StaticImage
													loading="eager"
													placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
												<span>About Us</span>
											</Link>
											<div
												className="snd-sub-menu accordion-items-container"
												data-collapsed="true"
											>
												<ul>
													<li>
														<Link to="#">Why we started</Link>
													</li>
													<li>
														<Link to="#">Our values</Link>
													</li>
													<li>
														<Link to="#">Our team</Link>
													</li>
													<li>
														<Link to="#">Our advisors</Link>
													</li>
												</ul>
											</div>
										</li>
									</ul>
									<ul>
										<li className="accordion">
											<Link
												to="#"
												className="snd-sub-menu-title accordion-link"
											>
												<StaticImage
													loading="eager"
													placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
												<span>Client stories us</span>
											</Link>
											<div
												className="snd-sub-menu accordion-items-container"
												data-collapsed="true"
											>
												<ul>
													<li>
														<Link to="#">Matthew's story</Link>
													</li>
													<li>
														<Link to="#">Iwona &amp; Neil's story</Link>
													</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</li>
							<li className="accordion">
								<Link to="#" className="accordion-link">
									<StaticImage
										loading="eager"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
										alt=""
									/>
									<span>Conditions we care for</span>
								</Link>
								<div
									className="accordion-items-container"
									data-collapsed="true"
								>
									<ul>
										<li>
											<Link to="/dementia">Dementia</Link>
										</li>
										<li>
											<Link to="#">Parkinsons</Link>
										</li>
										<li>
											<Link to="#">Palliative Care</Link>
										</li>
									</ul>
									<ul>
										<li>
											<Link to="#">Daily active tasks</Link>
										</li>
										<li>
											<Link to="#">Couples Care</Link>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
					<div className="navigation-list">
						<ul className="menu-list mobile">
							<li className="accordion">
								<Link to="#" className="accordion-link">
									<StaticImage
										loading="eager"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
										alt=""
									/>
									<span>Locations</span>
								</Link>
								<div
									className="accordion-items-container"
									data-collapsed="true"
								>
									<ul>
										<li>
											<Link to="/locations">London</Link>
										</li>
										<li>
											<Link to="/locations"> East Midlands</Link>
										</li>
										<li>
											<Link to="/locations"> West Of England</Link>
										</li>
										<li>
											<Link to="/locations"> North East</Link>
										</li>
									</ul>
									<ul>
										<li>
											<Link to="/locations"> North West</Link>
										</li>
										<li>
											<Link to="/locations">South East</Link>
										</li>
										<li>
											<Link to="/locations"> South West</Link>
										</li>
										<li>
											<Link to="/locations">West Midlands</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="accordion">
								<Link to="#" className="accordion-link">
									<StaticImage
										loading="eager"
										placeholder="blurred"
										src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
										alt=""
									/>
									<span>Planning & advice</span>
								</Link>
								<div
									className="accordion-items-container"
									data-collapsed="true"
								>
									<ul>
										<li className="accordion">
											<Link to="#" className="accordion-link">
												<StaticImage
													loading="eager"
													placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
												<span>Starting the conversation</span>
											</Link>
											<div
												className="accordion-items-container"
												data-collapsed="true"
											>
												<ul>
													<li>
														<Link to="/blog">Blog title goes here</Link>
													</li>
													<li>
														<Link to="blog-post.html">
															Blog title goes here
														</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
												</ul>
											</div>
										</li>
										<li className="accordion">
											<Link to="#" className="accordion-link">
												<StaticImage
													loading="eager"
													placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
												<span>Preparing for care</span>
											</Link>
											<div
												className="accordion-items-container"
												data-collapsed="true"
											>
												<ul>
													<li>
														<Link to="/blog">Blog title goes here</Link>
													</li>
													<li>
														<Link to="blog-post.html">
															Blog title goes here
														</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
												</ul>
											</div>
										</li>
										<li className="accordion">
											<Link to="#" className="accordion-link">
												<StaticImage
													loading="eager"
													placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
												<span>Paying for care</span>
											</Link>
											<div
												className="accordion-items-container"
												data-collapsed="true"
											>
												<ul>
													<li>
														<Link to="/blog">Blog title goes here</Link>
													</li>
													<li>
														<Link to="blog-post.html">
															Blog title goes here
														</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
												</ul>
											</div>
										</li>
										<li className="accordion">
											<Link to="#" className="accordion-link">
												<StaticImage
													loading="eager"
													placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
												<span>Helpful guides</span>
											</Link>
											<div
												className="accordion-items-container"
												data-collapsed="true"
											>
												<ul>
													<li>
														<Link to="/blog">Blog title goes here</Link>
													</li>
													<li>
														<Link to="blog-post.html">
															Blog title goes here
														</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
													<li>
														<Link to="#">Blog title goes here</Link>
													</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
					<div className="mobile-nav-btn-container">
						<button className="btn">Login</button>
						<button className="btn btn-primary">Arange a Call</button>
					</div>
				</main>
			</nav>
		</>
	);
}
